.fipListRoot {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: var(--root-height);
  box-sizing: border-box;
}
.fipListBody {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
  height: calc(100% - 50px);
}
.textHeader {
  font-size: 24px;
  font-weight: 600;
  color: var(--header-text-color);
}
.textDescription {
  color: var(--description-text-color);
}
.allFipsContainer {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.popularFipsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.popularFipsCard {
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33% - 6px);
  aspect-ratio: 1/1;
  max-height: 125px;
  box-sizing: border-box;
  gap: 10px;
  cursor: pointer;
  border-radius: 16px;
  background-color: var(--card-color);
}
.bankName {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  width: 70%;
  color: gray;
  text-align: center;
}
.selectedBankName {
  font-weight: 600;
  color: var(--header-text-color);
}
.secondaryHeader {
  color: var(--header-text-color);
}
.selectedPopularFipsCard {
  border-color: var(--selected-border-color);
  border-width: 2px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.bankInputSearch::before {
  content: '';
}
.header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: sticky;
  top: 0;
  background: var(--primary-backgroud-color);
  z-index: 5;
  padding-bottom: 10px;
}
.allBanksHeader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 5;
  position: sticky;
  top: 61px;
  background: var(--primary-backgroud-color);
  padding: 10px 0;
}
.bankInputSearch {
  border: none;
  width: 100%;
  background: var(--primary-backgroud-color);
  color: var(--text-color);
}
.bankInputSearch::placeholder {
  color: var(--text-color);
}
.bankInputSearch:focus-visible {
  outline: none;
}
.searchContainer {
  border: 1px solid rgb(201, 201, 201);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.primaryButton {
  color: var(--primary-button-text-color);
  background: var(--primary-button-color);
  border: none;
  padding: 12px;
  border-radius: var(--button-border-radius);
  font-weight: 600;
}
.primaryButton:disabled {
  background: var(--disabled-button-color);
}

.flowSelectionContainer {
  padding: 15px;
}
.flowSelectionContainer h2 {
  color: var(--text-color);
}
.flowSelection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.method {
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid var(--border-color);
  background-color: var(--card-color);
}

.methodIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  color: var(--text-color);
}

.methodArrow img {
  width: 20px;
  height: 20px;
}

.methodInfo h2 {
  font-size: 1em;
}

.methodInfo p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #6c757d;
}

.verificationType {
  display: block;
  font-size: 0.8em;
  color: #6c757d;
  margin-top: 10px;
}
.flowSelectionHeader {
  color: var(--header-text-color);
}
.rightIcon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  color: var(--text-color);
}
