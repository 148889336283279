@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --navabar-background-color: #fff;
  --primary-background-color: #fff;
  --primary-color: #1260ff;
  --secondary-color: #48dda1;
  --primary-button-text-color: #fff;
  --primary-button-color: #1260ff;
  --disabled-button-color: #6699ff;
  --on-hover-button-color: #48dda1;
  --header-text-color: #1b1b1b;
  --description-text-color: rgb(169, 169, 169);
  --button-border-radius: 8px;
  --primary-backgroud-color: #fff;
  --text-color: #000000;
  --card-color: #ffffff;
  --border-color: #1260ff;
  --root-height: calc(100% - 65px);
}

#root {
  background-color: var(--primary-backgroud-color);
  height: var(--doc-height);
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: var(--font-family);
}

.App {
  max-width: 756px;
  width: 100%;
}
.appNavBar {
  padding: 10px;
  height: 40px;
  border-bottom: 2px solid var(--primary-color);
}
.loader {
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--primary-color);
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.noSelect,
.ant-typography {
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
  -o-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}
